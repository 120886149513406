export const REGISTRATION_TAG_PATHS = {
  how: "InfoDonor",
  requirements: "InfoDonor",
  blooddonation: "InfoDonor",
  "donorstvo-kostnogo-mozga": "InfoDonor",
  about: "InfoProject",
  smi: "InfoProject",
  awards: "InfoProject",
  "non-profit": "InfoProject",
  event: "Event",
  organization: "Organization",
  bonus: "Bonus",
  bloodstation: "Blood",
  recipient: "Recipient",
  helpme: "Helpme",
  top: "Top",
  advice: "Advice",
  pre_sign_up: "Register",
  confidential: "Register",
  rules: "Register",
};
